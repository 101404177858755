import React, { useEffect, useRef } from 'react';
import H from '@here/maps-api-for-javascript';
import './Map.css';
import '@here/maps-api-for-javascript/bin/mapsjs-ui.css';

function Map(props) {
    const mapRef = useRef(null);
    const map = useRef(null);
    const platform = useRef(null);
    const {
        apikey, setETA, destination, technicianPosition,
    } = props;
    useEffect(
        () => {
            // Check if the map object has already been created
            if (!map.current) {
                // Create a platform object with the API key
                platform.current = new H.service.Platform({ apikey });
                // Create a new Raster Tile service instance
                const rasterTileService = platform.current.getRasterTileService({
                    queryParams: {
                        style: 'explore.day',
                        size: 512,
                    },
                });
                // Creates a new instance of the H.service.rasterTile.Provider class
                // The class provides raster tiles for a given tile layer ID and pixel format
                const rasterTileProvider = new H.service.rasterTile.Provider(
                    rasterTileService,
                );
                // Create a new Tile layer with the Raster Tile provider
                const rasterTileLayer = new H.map.layer.TileLayer(rasterTileProvider);
                // Create a new map instance with the Tile layer, center and zoom level
                const newMap = new H.Map(mapRef.current, rasterTileLayer, {
                    pixelRatio: window.devicePixelRatio,
                    center: { lat: technicianPosition.lat, lng: technicianPosition.lng },
                    zoom: 16,
                });

                // Default UI
                H.ui.UI.createDefault(newMap, platform.current.createDefaultLayers());

                // Add panning and zooming behavior to the map
                new H.mapevents.Behavior(
                    new H.mapevents.MapEvents(newMap),
                );

                // Set the map object to the reference
                map.current = newMap;
            }
            if (technicianPosition) {
                calculateRoute(platform.current, map.current, destination, technicianPosition, setETA);
            }
        },
        // Dependencies array
        [apikey, destination, technicianPosition],

    );

    // Return a div element to hold the map
    return <div className="map" ref={mapRef} />;
}

function getTechIcon() {
    const svgTech = `<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.216797" width="40" height="40" rx="20" fill="black"/>
<path d="M21.8 10.2168C22.7938 10.2168 23.6 11.0227 23.6 12.0168V13.8168H25.5013C26.1388 13.8168 26.7163 14.068 27.2 14.518L30.0988 17.4168C30.5487 17.8668 30.8 18.478 30.8 19.1155V23.4168C31.4638 23.4168 32 23.953 32 24.6168C32 25.2805 31.4638 25.8168 30.8 25.8168H29.6C29.6 27.8043 27.9875 29.4168 26 29.4168C23.9788 29.4168 22.4 27.8043 22.4 25.8168H17.6C17.6 27.8043 15.9875 29.4168 14 29.4168C11.9787 29.4168 10.4 27.8043 10.4 25.8168H9.8C8.80588 25.8168 8 25.0105 8 24.0168V12.0168C8 11.0227 8.80588 10.2168 9.8 10.2168H21.8ZM23.6 16.2168V19.8168H28.4V19.1155L25.5013 16.2168H23.6ZM14 24.0168C13.0063 24.0168 12.2 24.823 12.2 25.8168C12.2 26.8105 13.0063 27.6168 14 27.6168C14.9938 27.6168 15.8 26.8105 15.8 25.8168C15.8 24.823 14.9938 24.0168 14 24.0168ZM26 27.6168C26.9938 27.6168 27.8 26.8105 27.8 25.8168C27.8 24.823 26.9938 24.0168 26 24.0168C25.0063 24.0168 24.2 24.823 24.2 25.8168C24.2 26.8105 25.0063 27.6168 26 27.6168Z" fill="white"/>
</svg>`;
    return new H.map.Icon(svgTech, {
        anchor: {
            x: 10,
            y: 10,
        },
    });
}
function getDestIcon() {
    const svgDest = `<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.216797" width="40" height="40" rx="20" fill="#F16E00"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.002 10.2158C16.132 10.2158 13.002 13.3458 13.002 17.2158C13.002 22.4658 20.002 30.2158 20.002 30.2158C20.002 30.2158 27.002 22.4658 27.002 17.2158C27.002 13.3458 23.872 10.2158 20.002 10.2158ZM20.002 19.7158C18.622 19.7158 17.502 18.5958 17.502 17.2158C17.502 15.8358 18.622 14.7158 20.002 14.7158C21.382 14.7158 22.502 15.8358 22.502 17.2158C22.502 18.5958 21.382 19.7158 20.002 19.7158Z" fill="black"/>
</svg>`;
    return new H.map.Icon(svgDest, {
        anchor: {
            x: 10,
            y: 10,
        },
    });
}

function calculateRoute(platform, map, start, destination, setETA) {
    function routeResponseHandler(response) {
        setETA(new Date(response.routes[0].sections[0].arrival.time));
        // console.log(new Date(response.routes[0].sections[0].arrival.time).toTimeString());
        const sections = response.routes[0].sections;
        const lineStrings = [];
        sections.forEach((section) => {
            // convert Flexible Polyline encoded string to geometry
            lineStrings.push(H.geo.LineString.fromFlexiblePolyline(section.polyline));
        });
        const multiLineString = new H.geo.MultiLineString(lineStrings);

        // Create the polyline for the route
        const routePolyline = new H.map.Polyline(multiLineString, {
            style: {
                lineWidth: 4,
                strokeColor: 'black',
            },
        });

        // Calculate the bounding box
        const minLat = Math.min(start.lat, destination.lat);
        const minLng = Math.min(start.lng, destination.lng);
        const maxLat = Math.max(start.lat, destination.lat);
        const maxLng = Math.max(start.lng, destination.lng);

        // Add a margin to see the icons
        const latMargin = (maxLat - minLat) * 0.15;
        const lngMargin = (maxLng - minLng) * 0.15;

        // Create the bounding box with the margins
        const boundingBox = new H.geo.Rect(
            minLat - latMargin,
            minLng - lngMargin,
            maxLat + latMargin,
            maxLng + lngMargin,
        );

        // Adapt the zoom
        map.getViewModel().setLookAtData({
            bounds: boundingBox,
        });

        // Remove all the previous map objects, if any
        map.removeObjects(map.getObjects());
        // Add the polyline to the map
        map.addObject(routePolyline);
        map.addObjects([
            // Add a marker for the destination
            new H.map.Marker(destination, {
                icon: getDestIcon(),
            }),
            // Add a marker for the technician
            new H.map.Marker(start, {
                icon: getTechIcon(),
            }),
        ]);
    }

    // Get an instance of the H.service.RoutingService8 service
    const router = platform.getRoutingService(null, 8);

    // Define the routing service parameters
    const routingParams = {
        origin: `${start.lat},${start.lng}`,
        destination: `${destination.lat},${destination.lng}`,
        transportMode: 'car',
        return: 'polyline',
    };
    // Call the routing service with the defined parameters
    router.calculateRoute(routingParams, routeResponseHandler, console.error);
}

export default Map;
