import { Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map from '../ETAMap/Map';
import './TechTracking.css';
import BookingConfirmation from '../Confirmation/BookingConfirmation';

export default function TechTracking(props) {
    const { t } = useTranslation('common');
    const [ETA, setETA] = useState();

    function defineETA(newETA) {
        setETA(newETA.toLocaleTimeString(
            t('date.locale'),
            {
                hour: '2-digit',
                minute: '2-digit',
            },
        ));
    }

    const trackingTitle = (
        <>
            {t('tracking.arrivalTime')}
            <br />
            {`${t('tracking.arrivalTimePrep')} ${ETA}`}
        </>
    );

    return (
        <div className="selectWrapper trackingWrapper">
            <div className="trackingDetails">
                <h2 className="trackingDetails-title">{ETA ? trackingTitle : t('tracking.error.geolocationFailureTitle')}</h2>
                <Divider className="trackingDivider" orientation="left" />
                <BookingConfirmation
                    workOrder={props.workOrder}
                    selectedSlot={props.selectedSlot}
                    additonalInfo={props.additionalInfo}
                    newPhoneNumber={props.newPhoneNumber}
                    newEmailAddress={props.newEmailAddress}
                    rescheduleAppointment={props.rescheduleAppointment}
                    saveCalendar={() => props.setOpenDrawer(true)}
                    withRescheduleButton
                />
            </div>
            <Map
                className="trackingMap"
                apikey={props.apiKey}
                setETA={defineETA}
                technicianPosition={{ lat: props.latDest, lng: props.lngDest }}
                destination={{ lat: props.latTech, lng: props.lngTech }}
            />
        </div>
    );
}
