import React, { useEffect, useState } from 'react';
import { Button, Collapse } from 'antd';
import './SlotSelection.css';
import { useTranslation } from 'react-i18next';

// TODO use typescript to define parameter : interface SlotProperties { entId : Number, duration :...}
export default function SlotSelection(props) {
    const { t } = useTranslation('common');
    const [slots, setSlots] = useState<any[]>([]);
    const [selectedSlot, setSelectedSlot] = useState();
    const [dateRange = [2, 7], setDateRange] = useState<number[]>();
    useEffect(() => {
        (async () => getSlots(dateRange))();
    }, []);
    const { Panel } = Collapse;

    async function getSlots(pDateRange) {
        const startDate = new Date(new Date().setDate(new Date().getDate() + pDateRange[0])).toISOString();
        const endDate = new Date(new Date().setDate(new Date().getDate() + pDateRange[1])).toISOString();
        const slots = await fetch(`/service/${props.uuid}/getSlot?startDate=${startDate}&endDate=${endDate}`)
            .then((response) => response.json())
            .catch((error) => console.error(error));
        setSlots(slots.slots);
    }

    function handleClick(slot) {
        setSelectedSlot(slot);
        props.selectSlot(slot);
    }

    async function handleRangeChange() {
        const pDateRange = [dateRange[0], dateRange[1] + 5];
        setDateRange([dateRange[0], dateRange[1] + 5]);
        props.resetSlot();
        await getSlots(pDateRange);
    }

    if (slots !== undefined) {
        const slotsDates = [...new Set(slots.map((slot) => new Date(slot.start).toLocaleDateString(t('date.locale'), {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }).charAt(0).toUpperCase() + new Date(slot.start).toLocaleDateString(t('date.locale'), {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        }).substring(1)))];
        if (slots.length !== 0) {
            return (
                <>
                    <span>{t('slots.title')}</span>
                    <div className="slotWrapper">
                        {
                            slotsDates.map((date, indexDate) => (
                                <div key={indexDate} className="dateContainer">
                                    <Collapse size="small" expandIconPosition="end">
                                        <Panel key={indexDate} header={date}>
                                            <div className="panelContent">
                                                {slots.filter((slot1, index) => slots.findIndex((slotday) => slotday.start === slot1.start) === index).filter((slot) => new Date(slot.start).toLocaleDateString(t('date.locale'), {
                                                    weekday: 'long',
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                }).charAt(0).toUpperCase() + new Date(slot.start).toLocaleDateString(t('date.locale'), {
                                                    weekday: 'long',
                                                    year: 'numeric',
                                                    month: 'numeric',
                                                    day: 'numeric',
                                                }).substring(1) === date).map((slot, index) => (
                                                    <Button
                                                        type={(slot === selectedSlot) ? 'primary' : 'default'}
                                                        key={String(indexDate) + String(index)}
                                                        className="slotSelectButton"
                                                        onClick={() => handleClick(slot)}
                                                    >
                                                        {new Date(slot.start).toLocaleTimeString(t('date.locale'), {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                        {' '}
                                                        -
                                                        {new Date(new Date(slot.end).getTime() + props.authorizedDelay * 60000).toLocaleTimeString(t('date.locale'), {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                        })}
                                                    </Button>
                                                ))}
                                            </div>
                                        </Panel>
                                    </Collapse>

                                </div>
                            ))
                        }
                    </div>
                    <div className="additionalDates" onClick={() => handleRangeChange()}>{t('slots.more')}</div>
                </>
            );
        } return (
            <>
                <div className="slotWrapper">
                    <div>{t('slots.unavailable')}</div>
                </div>
                <div className="additionalDates" onClick={() => handleRangeChange()}>{t('slots.more')}</div>
            </>
        );
    } return (
        <div>{t('slots.none')}</div>
    );
}
